import {
  SPLIT_IMAGE,
  FULL_WIDTH,
  CENTERED_WITH_PADDING,
  IMAGE_TEXT_RIGHT,
  IMAGE_TEXT_LEFT,
  TEXT,
  VIDEO,
} from "../constants/"

import SplitImages from "./article/SplitImages"
import ImageFullWidth from "./article/ImageFullWidth"
import ImageCenteredWithPadding from "./article/ImageCenteredWithPadding"
import ImageWithText from "./article/ImageWithText"
import TextBlock from "./article/TextBlock"
import VideoFullWidth from "./article/VideoFullWidth"

export const articleComponents = {
  [SPLIT_IMAGE]: SplitImages,
  [FULL_WIDTH]: ImageFullWidth,
  [CENTERED_WITH_PADDING]: ImageCenteredWithPadding,
  [IMAGE_TEXT_RIGHT]: ImageWithText,
  [IMAGE_TEXT_LEFT]: ImageWithText,
  [TEXT]: TextBlock,
  [VIDEO]: VideoFullWidth,
}
