import React from "react"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styled from "styled-components"

export default function ProjectDescription({
  description,
  collaborators,
  collaboratorsTitle,
}) {
  return (
    <Container>
      <div>
        <Desc>{documentToReactComponents(description.json)}</Desc>
        {collaboratorsTitle && (
          <CollaboratorsTitle>{collaboratorsTitle}</CollaboratorsTitle>
        )}
        {collaborators && (
          <Collaborators>
            {documentToReactComponents(collaborators.json)}
          </Collaborators>
        )}
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;

  margin: 40px 0;

  @media (min-width: 500px) {
    margin: 100px 0;
  }

  > div {
    width: calc(100% - 40px);
    max-width: 650px;
  }

  * {
    line-height: 1.3em;
    letter-spacing: 0.05em;
  }

  a {
    text-transform: none !important;
    font-family: "DinMedium", "Helvetica Neue", Helvetica, Arial, sans-serif;

    position: relative;
    transform: translate3d(0, 0, 0);
    outline: none;
    -webkit-tap-highlight-color: transparent;

    a:link,
    a:focus,
    a:visited {
      position: relative;
      display: block;
      color: #fff;
      z-index: 3;
      transition: color 300ms cubic-bezier(0.6, 0.04, 0.98, 0.335);
      cursor: pointer;
    }

    &:hover {
      color: #404041;
      z-index: 3;
      transition: color 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &:hover:after {
      height: 50%;
      transition: height 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    a:active:after {
      height: 0.1rem;
      transition: height 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &:after {
      position: absolute;
      display: block;
      bottom: 0;
      left: -2%;
      background: #4fbbeb;
      height: 0rem;
      width: 104%;
      content: "";
      transition: height 300ms cubic-bezier(0.6, 0.04, 0.98, 0.335);
      z-index: -1 !important;
    }
  }
`

const Desc = styled.div`
  margin-bottom: 20px;
`

const CollaboratorsTitle = styled.div`
  font-family: "DinMedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  text-transform: uppercase;
`

const Collaborators = styled.div`
  text-align: center;
`
