import React from "react"
import PropTypes from "prop-types"

import { IMAGE_TEXT_LEFT } from "../../constants/"

import FadeInView from "../common/FadeInView"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { get, first } from "lodash"
import styled, { css } from "styled-components"
import mq from "../../style/mediaQueries"

const ImageWithText = ({ body, images, type }) => {
  const img = first(images)
  const fluid = get(img, "fluid")
  const textLeft = type === IMAGE_TEXT_LEFT

  const bodyComp = body ? (
    <div className="txt">{documentToReactComponents(body.json)}</div>
  ) : null

  const imgComp = fluid ? (
    <ContainImage
      fluid={fluid}
      backgroundColor="rgba(0, 0, 0, 0.03)"
      alt={img.title}
    />
  ) : null

  return (
    <FadeInView>
      {textLeft ? (
        <Row textLeft={textLeft}>
          {imgComp}
          {bodyComp}
        </Row>
      ) : (
        <Row textLeft={textLeft}>
          {bodyComp}
          {imgComp}
        </Row>
      )}
    </FadeInView>
  )
}

ImageWithText.propTypes = {
  images: PropTypes.array,
}

export default ImageWithText

const Row = styled.div`
  ${({ textLeft }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${mq.md`
    flex-direction: row;
  `};

    p {
      font-family: "Din", "Helvetica Neue", Helvetica, Arial, sans-serif;

      line-height: 1.5rem;

      b,
      strong {
        font-family: "DinMedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
    }
    h2 {
      font-family: "DinMedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 24px;
      line-height: 1.6;

      @media (min-width: 1100px) {
        font-size: 40px;
        font-size: 2vw;
        line-height: 3.5vw;
      }
    }

    .txt {
      padding: 5%;
      margin: 5% 0;
      color: #404041;

      max-width: 800px;

      @media (min-width: 1460px) {
        ${!textLeft
          ? css`
              padding-left: 0;
            `
          : css`
              padding-right: 0;
            `};
      }

      a {
        text-transform: none !important;
        font-family: "DinMedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
        position: relative;
        transform: translate3d(0, 0, 0);
        outline: none;
        white-space: nowrap;

        -webkit-tap-highlight-color: transparent;

        a:link,
        a:focus,
        a:visited {
          position: relative;
          display: block;
          color: #404041;
          z-index: 3;
          transition: color 300ms cubic-bezier(0.6, 0.04, 0.98, 0.335);
          cursor: pointer;
        }

        &:hover {
          color: #404041;
          z-index: 3;
          transition: color 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        &:hover:after {
          height: 50%;
          transition: height 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        a:active:after {
          height: 0.1rem;
          transition: height 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        &:after {
          position: absolute;
          display: block;
          bottom: 0;
          left: -2%;
          background: #4fbbeb;
          height: 0;
          width: 104%;
          content: "";
          transition: height 300ms cubic-bezier(0.6, 0.04, 0.98, 0.335);
          z-index: -1 !important;
        }
      }
    }
  `};
`

const ContainImage = styled(Img)`
  width: 100%;
  max-width: 600px;
`
