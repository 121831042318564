import React from "react"
import { times } from "lodash"
import { TweenMax } from "gsap"
// import { Quad } from "gsap/TweenMax"
import ease from "../constants/ease"

let TransitionMixin = {
  getDefaultProps() {
    return {
      color: "#E7E7E7",
      time: 0.75,
      mulit: 0.15,
      ease: ease("inOutQuad"),
    }
  },

  componentDidMount(prevProps, prevState) {
    this.arr = []
    times(9, i => {
      this.arr.push(this["r" + i])
    })

    this.transitionIn()
  },

  transitionIn() {
    let mask = this.mask

    TweenMax.killTweensOf(this.arr)
    TweenMax.killTweensOf(mask)
    TweenMax.set(mask, { display: "block" })
    TweenMax.set(this.arr, { opacity: 1, strokeOpacity: 1, strokeWidth: 1 })
    TweenMax.to(this.arr, {
      duration: 0.25,
      stagger: 0.05,
      opacity: 0,
      strokeOpacity: 0,
      strokeWidth: 0,
      ease: this.props.ease,
      delay: 0.5,
      onComplete: () => {
        TweenMax.set(mask, { display: "none" })
      },
    })
  },

  getMask() {
    return (
      <div ref={i => (this.mask = i)} className="page-mask">
        <svg viewBox="0 0 1024 720" preserveAspectRatio="none">
          <g
            id="mask"
            fill={this.props.color}
            stroke={this.props.color}
            strokeWidth={0}
          >
            <rect
              ref={i => (this.r0 = i)}
              x={0}
              y={0}
              width={1024}
              height={80}
            />
            <rect
              ref={i => (this.r1 = i)}
              x={0}
              y={80}
              width={1024}
              height={80}
            />
            <rect
              ref={i => (this.r2 = i)}
              x={0}
              y={160}
              width={1024}
              height={80}
            />
            <rect
              ref={i => (this.r3 = i)}
              x={0}
              y={240}
              width={1024}
              height={80}
            />
            <rect
              ref={i => (this.r4 = i)}
              x={0}
              y={320}
              width={1024}
              height={80}
            />
            <rect
              ref={i => (this.r5 = i)}
              x={0}
              y={400}
              width={1024}
              height={80}
            />
            <rect
              ref={i => (this.r6 = i)}
              x={0}
              y={480}
              width={1024}
              height={80}
            />
            <rect
              ref={i => (this.r7 = i)}
              x={0}
              y={560}
              width={1024}
              height={80}
            />
            <rect
              ref={i => (this.r8 = i)}
              x={0}
              y={640}
              width={1024}
              height={80}
            />
          </g>
        </svg>
      </div>
    )
  },
}

export default TransitionMixin
