import React, { useRef } from "react"
import createClass from "create-react-class"

import { GALLERY_TYPE_ARTICLE } from "../../constants/index"

import ImageGrid from "../components/ImageGrid"
import ImageLayout from "../common/ImageLayout"
import SecretMixin from "../mixins/SecretMixin"
import TransitionMixin from "../mixins/TransitionMixin.jsx"
import ProjectHero from "../components/ProjectHero"
import ProjectDescription from "../common/ProjectDescription"

import { get, indexOf } from "lodash"
import styled from "styled-components"
import useAnimateOnMount from "../../hooks/useAnimateOnMount"

const Project = ({ pathname, path, set, pagePath, pathId, ...props }) => {
  const ref = useRef()
  useAnimateOnMount(ref)

  return (
    <div ref={ref}>
      <ProjectPassword
        key={path}
        {...props}
        id={props.contentfulid}
        path={path}
        pathname={pathname}
      />
    </div>
  )
}

export default Project

const ProjectPassword = createClass({
  mixins: [SecretMixin],

  render() {
    const { path, password } = this.props
    const isClient = path === "clients"

    if (isClient && this.state.showPassword && password) {
      return this.secretInput()
    } else {
      return <ProjectDisplay {...this.state} {...this.props} />
    }
  },
})

const ProjectDisplay = createClass({
  mixins: [TransitionMixin],

  getInitialState() {
    return {
      checked: true,
    }
  },

  getSet(path, id) {
    const { pages } = this.props
    return get(pages, `${path}.sets.${id}`)
  },

  getPrevNextProject() {
    const { id, path, pages } = this.props
    const set = get(pages, `${path}.sets`)

    if (!!set) {
      const keys = Object.keys(set)
      const index = indexOf(keys, id)
      const len = keys.length - 1
      let previousNum = index - 1
      if (previousNum < 0) previousNum = len

      let nextNum = index + 1
      if (nextNum > len) nextNum = 0

      const prevNext = {
        prev: this.getSet(path, keys[previousNum]),
        next: this.getSet(path, keys[nextNum]),
        path,
      }

      return prevNext
    }
    return null
  },

  render() {
    const {
      articleLayout,
      description,
      collaborators,
      collaboratorsTitle,
      findIndex,
      id,
      path,
      images,
      imagesLayoutType,
      headerImage,
      featuredImage,
      title,
      isBrowser,
      projectSets,
      blurHeaderAmount,
      addHeaderNoise,
    } = this.props
    // const isClient = path === "clients"
    const layoutIsArticle = imagesLayoutType === GALLERY_TYPE_ARTICLE
    const heroImage = headerImage || featuredImage

    return (
      <Container className="page">
        {isBrowser && path === "projects" && (
          <>
            <ProjectHero
              {...heroImage}
              title={title}
              isBrowser={isBrowser}
              id={id}
              projectSets={projectSets}
              addNoise={addHeaderNoise || false}
              blurAmount={blurHeaderAmount || 0}
            />
            {description && (
              <ProjectDescription
                description={description}
                collaborators={collaborators}
                collaboratorsTitle={collaboratorsTitle}
              />
            )}
          </>
        )}
        <div>
          {this.getMask()}

          {layoutIsArticle ? (
            <ImageLayout content={articleLayout} />
          ) : (
            <div ref="grid" className="project-list">
              <ImageGrid
                isBrowser={isBrowser}
                findIndex={findIndex}
                data={{
                  set: path,
                  setID: id,
                  images,
                }}
              />
            </div>
          )}
        </div>
      </Container>
    )
  },
})

const Container = styled.div`
  padding-bottom: 40px;

  @media (min-width: 500px) {
    padding-bottom: 60px;
  }
`
