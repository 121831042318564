import React from "react"

import FadeInView from "../common/FadeInView"
import ReactPlayer from "react-player"

import styled from "styled-components"

const VideoFullWidth = ({ videoUrl }) => {
  return (
    <FadeInView>
      <Container>
        <Div>
          {!!videoUrl && (
            <ReactPlayer height="100%" width="100%" url={videoUrl} controls />
          )}
        </Div>
      </Container>
    </FadeInView>
  )
}

VideoFullWidth.propTypes = {}

export default VideoFullWidth

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100vw);
`

const Div = styled.div`
  width: calc(100vw);
  height: calc(100vw * 9 / 16);

  @media (min-width: 1420px) {
    width: 1400px;
    height: calc(1400px * 9 / 16);
  }
`
