import { css } from 'styled-components'
import { breakpoints } from './theme'

/*
  This creates the media templates, which allows for simple
  breakpoint usage inside styled-components, e.g.:

  ${Media.sm`
    font-size: 1.6rem;
  `};

  ${Media.lg`
    display: flex;
  `};

  Edit or add breakpoints inside style/theme.js
 */

const Media = Object.keys(breakpoints).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${breakpoints[label]}em) {
      ${css(...args)};
    }
  `
  return acc
}, {})

export default Media
