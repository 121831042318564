import React from "react"
import PropTypes from "prop-types"

import FadeInView from "../common/FadeInView"
import Img from "gatsby-image"

import { get, first, last } from "lodash"
import { modularScale } from "polished"
import styled, { css } from "styled-components"

const SplitImages = ({ images, rightImagePercent }) => {
  const firstImage = get(first(images), "fluid.aspectRatio")
  const lastImage = get(last(images), "fluid.aspectRatio")
  return (
    <FadeInView>
      <Row
        percent={rightImagePercent}
        aspectFirst={firstImage}
        aspectLast={lastImage}
      >
        {images.map((img, i) => {
          const fluid = get(img, "fluid")
          return (
            <FillImage
              key={`img${i}`}
              fluid={fluid}
              backgroundColor="rgba(0, 0, 0, 0.03)"
              alt={img.title}
            />
          )
        })}
      </Row>
    </FadeInView>
  )
}

SplitImages.propTypes = {
  images: PropTypes.array,
}

export default SplitImages

const FillImage = styled(Img)`
  width: calc(100% + ${modularScale(3)});
  transform: translateX(calc(-${modularScale(3)} / 2));
`

const Row = styled.div`
  ${({ aspectFirst, aspectLast, percent }) => css`
    display: flex;
    width: 100%;

    .gatsby-image-wrapper {
      &:first-child {
        width: calc(${percent}vw);
        height: calc(${percent}vw * ${aspectFirst});
      }

      &:last-child {
        transform: translateX(10px);
        width: calc(${100 - percent}vw);
        height: calc(${percent}vw * ${aspectLast});
      }
    }
  `};
`
