import React from "react"
import createClass from "create-react-class"
import { Link } from "gatsby"
import Img from "gatsby-image"
import parseVideoURL from "../utils/parseVideoURL.js"
import classNames from "classnames"
import { get } from "lodash"
import styled from "styled-components"

const ImageButton = createClass({
  getInitialState() {
    return {
      isVideo: false,
    }
  },

  componentWillMount() {
    const video = get(this.props, "data.description")
    const isVideo = (video && video.length > 3) || false

    if (isVideo) {
      const videoObject = parseVideoURL(video)
      if (get(videoObject, "error")) {
        console.warn("Error", videoObject)
      } else {
        this.setState({ isVideo })
      }
    }
  },

  render() {
    const { fluid, file, set, setID, index } = this.props.data
    const url = `/detail/${set}/${setID}/${index + 1}`
    const cls = classNames(`${this.props.className} img-detail`, {
      "img-detail-video": this.state.isVideo,
    })

    const size = file?.details?.image || {}

    return (
      <Container className={cls}>
        <Link to={url}>
          <Img
            fadeIn={false}
            fluid={fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt=""
            backgroundColor="rgba(0,0,0,0.03)"
            onLoad={this.props.data.gridImageLoaded}
            {...size}
          />
          {this.state.isVideo && (
            <svg width={60} height={50} viewBox="0 0 28 32">
              <path d="M28.66,16l-29,16V0Z" />
            </svg>
          )}
        </Link>
      </Container>
    )
  },
})

ImageButton.displayName = "ImageButton"

export default ImageButton

const Container = styled.div`
  img,
  .gatsby-image-wrapper {
    transition: filter 0.5s ease-out !important;
  }

  &:hover {
    img,
    .gatsby-image-wrapper {
      filter: brightness(0.8);
      transition: filter 0.4s ease-out;
    }
  }
`
