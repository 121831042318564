import React, { memo } from "react"
import PropTypes from "prop-types"

import styled from "styled-components"
//import { useInView } from "react-intersection-observer"

const FadeInView = ({ children, ...props }) => {
  //const [ref, inView] = useInView({ threshold: 0.1, triggerOnce: false })
  const inView = true
  return (
    <Container
      {...props}
      //ref={ref}
      css={{
        opacity: inView ? 1 : 1,
      }}
    >
      {children}
    </Container>
  )
}

FadeInView.defaultProps = {
  delay: 100,
}

FadeInView.propTypes = {
  delay: PropTypes.number,
  children: PropTypes.node,
}

export default memo(FadeInView)

const Container = styled.div`
  transition: opacity 0.7s ease-out;
`
