import React, { Component } from "react"
import Masonry from "react-masonry-component"
import ImageButton from "../components/ImageButton"

class ImageGrid extends Component {
  componentDidMount() {
    setTimeout(this.scroll, 300)
  }

  scroll = () => {
    const { findIndex, isBrowser } = this.props
    if (isBrowser) {
      if (findIndex) {
        const el = this.container.querySelector(`.img-${findIndex}`)
        if (el) el.scrollIntoView()
      } else {
        document.body.scrollTop = 0
      }
    }
  }

  onImageLoaded = () => this.masonry.layout()

  render() {
    const { images, set, setID } = this.props.data
    return (
      <div ref={i => (this.container = i)}>
        <Masonry
          className="grid"
          ref={i => (this.masonry = this.masonry || i.masonry)}
          options={{ transitionDuration: 0 }}
          disableImagesLoaded={true}
          //updateOnEachImageLoad
          //onImagesLoaded={this.onImagesLoaded}
        >
          {images &&
            images.map((image, i) => {
              const data = {
                ...image,
                index: i,
                set,
                setID,
                gridImageLoaded: this.onImageLoaded,
              }
              return (
                <ImageButton
                  key={`img-${i}`}
                  className={`img-${i}`}
                  data={data}
                />
              )
            })}
        </Masonry>
      </div>
    )
  }
}

ImageGrid.displayName = "ImageGrid"

export default ImageGrid
