import React from "react"
import PropTypes from "prop-types"

import FadeInView from "../common/FadeInView"
import Img from "gatsby-image"

import { get, first } from "lodash"
import { modularScale } from "polished"
import styled from "styled-components"

const ImageFullWidth = ({ images }) => {
  const img = first(images)
  const fluid = get(img, "fluid")
  return fluid ? (
    <FadeInView>
      <Center>
        <FillImage
          fluid={fluid}
          backgroundColor="rgba(0, 0, 0, 0.03)"
          alt={img?.title || ""}
        />
      </Center>
    </FadeInView>
  ) : null
}

ImageFullWidth.propTypes = {
  images: PropTypes.array,
}

export default ImageFullWidth

const FillImage = styled(Img)`

      /* width: calc(100% + ${modularScale(3)});
      transform: translateX(calc(-${modularScale(3)} / 2)); */
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;

  > * {
    width: 100%;
    max-width: 1400px;
  }
`
