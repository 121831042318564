import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Project from "../components/pages/Project"
import SEO from "../components/SeoMeta"
import ProjectsGrid from "../components/components/ProjectsGrid"
import NavSpacer from "../components/common/NavSpacer"

import { useAppContext } from "../context/AppContext"
import useSSR from "use-ssr"
import { get, keyBy, first } from "lodash"

const SetTemplate = ({ data, pageContext, location, ...props }) => {
  const findIndex = location?.state?.index
  const { setLogoColored } = useAppContext()
  const { isBrowser } = useSSR()
  const { siteMetadata } = data.site
  const sets = get(data, "allContentfulGallery.nodes")
  const keys = keyBy(sets, "contentfulid")
  const project = get(keys, pageContext.id)

  const pathname = location.pathname
    .split("/")
    .filter(path => path !== "" && path !== "set")
  const path = first(pathname)
  const id = path === "projects" ? "Project" : "Images"
  const gridIds = get(data, `allContentful${id}Section.nodes[0].sets`) || []
  const gridSet = gridIds
    .map(({ contentfulid }) => get(keys, contentfulid))
    .filter(item => item.contentfulid !== project.contentfulid)

  const seoImage = project.featuredImage.fixed

  const projectSets = get(data, "allContentfulProjectSection.nodes[0].sets")

  useEffect(() => {
    setLogoColored()
  }, [])

  return (
    <>
      <SEO {...siteMetadata} image={seoImage} title={`${project?.title}`} />
      <NavSpacer />

      <Project
        {...project}
        pathname={pathname}
        path={path}
        isBrowser={isBrowser}
        projectSets={projectSets}
        findIndex={findIndex}
      />

      <ProjectsGrid
        pagePath={path}
        sets={gridSet}
        classList="projects"
        time={2000}
      />
    </>
  )
}

export const query = graphql`
  query SetPageQuery {
    allContentfulImagesSection {
      nodes {
        sets {
          contentfulid
        }
      }
    }
    allContentfulProjectSection {
      nodes {
        sets {
          contentfulid
          title
        }
      }
    }
    allContentfulGallery {
      nodes {
        title
        contentfulid
        imagesLayoutType
        articleLayout {
          title
          rightImagePercent
          videoUrl
          body {
            json
          }
          images {
            file {
              details {
                image {
                  height
                  width
                }
              }
            }
            fluid(maxWidth: 1200) {
              srcWebp
              srcSetWebp
              src
              sizes
              srcSet
              aspectRatio
            }
          }
          type
        }
        collaboratorsTitle
        collaborators {
          id
          json
        }
        description {
          json
        }
        blurHeaderAmount
        addHeaderNoise
        headerImage {
          id
          title
          description
          fixed {
            height
            src
            width
          }
          fluid(maxWidth: 1400) {
            srcWebp
            srcSetWebp
            src
            sizes
            srcSet
            aspectRatio
          }
          file {
            details {
              image {
                height
                width
              }
            }
          }
        }
        featuredImage {
          id
          title
          description
          fixed(width: 1200, height: 630) {
            height
            src
            width
          }
          fluid(maxWidth: 800, maxHeight: 400) {
            srcWebp
            srcSetWebp
            src
            sizes
            srcSet
            aspectRatio
          }
          file {
            details {
              image {
                height
                width
              }
            }
          }
        }
        images {
          id
          title
          description
          fluid(maxWidth: 800) {
            srcWebp
            srcSetWebp
            src
            sizes
            srcSet
            aspectRatio
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default SetTemplate
