import React, { useState } from "react"
import PropTypes from "prop-types"

import FadeInView from "../common/FadeInView"
import Img from "gatsby-image"

import { get, first } from "lodash"
import styled, { css } from "styled-components"

const ImageCenteredWithPadding = ({ images }) => {
  const [useFallback, setUseFallback] = useState(false)
  const img = first(images)
  const fluid = get(img, "fluid")
  const ogimg = get(img, "ogimg")
  const aspectRatio = get(fluid, "aspectRatio")
  const { width, height } = img.file.details.image
  const isHorizontal = width > height

  return fluid ? (
    <FadeInView>
      {useFallback ? (
        <ContainImageFallback
          aspectRatio={aspectRatio}
          src={ogimg.src}
          alt={img.title}
          isHorizontal={isHorizontal}
        />
      ) : (
        <ContainImage
          fluid={fluid}
          backgroundColor="rgba(0, 0, 0, 0.03)"
          alt={img.title}
          isHorizontal={isHorizontal}
          onError={e => setUseFallback(true)}
        />
      )}
    </FadeInView>
  ) : null
}

ImageCenteredWithPadding.propTypes = {
  images: PropTypes.array,
}

export default ImageCenteredWithPadding

const ContainImage = styled(Img)`
  width: 70vw;
  max-width: ${({ isHorizontal }) => (isHorizontal ? 800 : 500)}px;
  margin: 0 auto;
`

const ContainImageFallback = styled.img`
  ${({ aspectRatio, isHorizontal }) => css`
    width: 70vw;
    height: calc(70vw * ${aspectRatio});
    max-width: ${isHorizontal ? 800 : 500}px;
    max-height: ${(isHorizontal ? 500 : 1000) * aspectRatio}px;
    object-fit: contain;
    margin: 0 auto;
  `};
`
