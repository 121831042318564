import React from "react"
import classNames from "classnames"
import createClass from "create-react-class"
import { TweenMax, gsap } from "gsap"
import ease from "../constants/ease"
import Img from "gatsby-background-image"
import { Link } from "gatsby"

import noise from "../../assets/img/noise.png"
import styled, { css } from "styled-components"

const ProjectHero = createClass({
  getInitialState() {
    return {
      ready: false,
    }
  },

  itemLoaded() {
    this.setState({ ready: true })
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.state.ready) this.animateText()
    //document.body.scrollTop = 0
  },

  componentDidMount() {
    if (this.props.isBrowser) {
      this.SplitText = require("../lib/greensock/SplitText")
      //this.animateText()
    }
  },

  animateText() {
    if (!this.props.isBrowser || !this.SplitText) return
    let el = this.heroTitle

    let tl = gsap.timeline({ delay: 0.5 })
    let mySplitText = new this.SplitText(el, { type: "words,chars" })
    let chars = mySplitText.chars

    TweenMax.set(el, { perspective: 400, visibility: "visible" })
    tl.staggerFrom(
      chars,
      0.75,
      {
        opacity: 0,
        ease: ease("inOutQuad"),
      },
      0.02,
      "start"
    )
    tl.staggerFrom(
      chars,
      0.75,
      {
        //scaleX: 0,
        y: "-50%",
        //rotationX: 180,
        transformOrigin: "100% 0% 0%",
        ease: ease("outExpo"),
      },
      0.02,
      "start"
    )
  },

  render() {
    const { addNoise, blurAmount, id, fluid, projectSets, title } = this.props
    const bgImg = classNames("hero-bg-c", {
      "img-loaded": this.state.ready,
    })

    const index = projectSets.map(i => i.contentfulid).indexOf(id)
    const len = projectSets.length
    const previous = projectSets[(index + len - 1) % len]
    const next = projectSets[(index + 1) % len]

    return (
      <div className="project-hero">
        <div className={bgImg}>
          <StyledImage
            blurAmount={blurAmount}
            fluid={fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt=""
            onLoad={() => {
              this.setState({ ready: true })
            }}
            fadeIn={false}
          />
          {addNoise && <Noise />}
        </div>
        <div className="hero-bg">
          <div
            ref={i => (this.heroTitle = i)}
            className="hero-title"
            style={{ visibility: "hidden" }}
          >
            {title}
          </div>
        </div>
        <div className="flex-center fs-nav">
          <div className="fs-nav-row fs-nav-low">
            <div ref={this.prev} className="fs-btn prev-btn">
              <Link to={`/set/projects/${previous.contentfulid}`}>
                <svg width="23px" height="27px" viewBox="0 0 23 27">
                  <g stroke="none" fill="none" fillRule="evenodd">
                    <path
                      d="M21,13.5 L1,2 L1,25 L21,13.5 L21,13.5 Z"
                      className="arrow-path"
                    />
                  </g>
                </svg>
              </Link>
            </div>

            <div ref={this.next} className="fs-btn next-btn">
              <Link to={`/set/projects/${next.contentfulid}`}>
                <svg width="23px" height="27px" viewBox="0 0 23 27">
                  <g stroke="none" fill="none" fillRule="evenodd">
                    <path
                      d="M21,13.5 L1,2 L1,25 L21,13.5 L21,13.5 Z"
                      className="arrow-path"
                    />
                  </g>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  },
})

export default ProjectHero

const Noise = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: url(${noise});
    background-repeat: repeat;
    opacity: 0.05;
  `
)

const StyledImage = styled(Img)`
  ${({ blurAmount }) => css`
    position: relative;
    opacity: 0;
    filter: blur(${blurAmount}px) brightness(0.6);
    width: 100%;
    height: 100%;
  `};
`
