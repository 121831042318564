export const PROJECTS = "projects"
export const IMAGES = "images"

export const GALLERY_TYPE_GRID = "grid"
export const GALLERY_TYPE_ARTICLE = "article"

export const SPLIT_IMAGE = "split-images"
export const FULL_WIDTH = "images-full-width"
export const CENTERED_WITH_PADDING = "image-centered-with-padding"
export const IMAGE_TEXT_RIGHT = "image-with-text-right"
export const IMAGE_TEXT_LEFT = "image-with-text-left"
export const TEXT = "just-text"
export const VIDEO = "video-full-width"
