import React from "react"

import FadeInView from "../common/FadeInView"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import styled from "styled-components"
import { modularScale } from "polished"
import mq from "../../style/mediaQueries"

const TextBlock = ({ body, textIsWhite }) => {
  return (
    <FadeInView>
      <StyledText>{documentToReactComponents(body.json)}</StyledText>
    </FadeInView>
  )
}

TextBlock.propTypes = {}

export default TextBlock

const StyledText = styled.div`
  padding: ${modularScale(6)} ${modularScale(0)};

  max-width: 600px;

  ${mq.xl`
    max-width: 800px;
  `};

  margin: 0 auto;

  h2 {
    font-family: "DinMedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #404041;
    font-size: 24px;
    line-height: 1.6;

    @media (min-width: 1100px) {
      font-size: 40px;
      font-size: 2vw;
      line-height: 3.5vw;
    }
  }

  p {
    font-family: "Din", "Helvetica Neue", Helvetica, Arial, sans-serif;

    line-height: 1.5rem;

    b,
    strong {
      font-family: "DinMedium", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    color: #404041;
  }
`
