import React from "react"
import PropTypes from "prop-types"

import { FULL_WIDTH } from "../../constants/index"

import styled, { css } from "styled-components"
import { articleComponents } from "../../components/articleComponents"

function ImageLayout({ content }) {
  return (
    <div>
      {!!content &&
        content.map(({ id, ...props }, i) => {
          const Content = articleComponents[props?.type]
          const isFullWidth = props?.type === FULL_WIDTH
          const last = content.length - 1
          return (
            <ContentSection
              key={`el${i}`}
              first={i === 0 && isFullWidth}
              css={{
                marginTop: i === 0 && isFullWidth ? -15 : 20,
                marginBottom: last && isFullWidth ? 0 : 20,
              }}
            >
              <Content {...props} />
            </ContentSection>
          )
        })}
    </div>
  )
}

ImageLayout.propTypes = { images: PropTypes.array }

export default ImageLayout

const ContentSection = styled.div(
  ({ first }) => css`
    max-width: 100vw;
    overflow-x: hidden;
    margin-top: ${first ? -15 : 20}px;

    @media (min-width: 1420px) {
      margin-top: ${20}px;
    }

    > div {
      color: #fff;
    }
  `
)
